import { useContext } from 'react'
import {
  availableDate,
  filterHrefs,
  getLowerPrice,
  getOutCome,
  getPropertyType,
  getUpperPrice,
  isExternalURL,
  titleCase,
} from '@lib/helpers'
import ListingSaleInfo from './ListingSaleInfo'
import ListingPrice from './ListingPrice'
import ListingTags from './ListingTags'
import { defaultImages } from '@lib/variables'
import { logEvent } from '@lib/analyticsService'
import { ACTIVEPIPE_COOKIE, getCookie } from '@lib/cookies'
import { UserContext } from '@context/UserContext'
import OffMarketOverlay from './OffMarketOverlay'
import ListingAuction from './ListingAuction'
import { Link } from '@routes'
import FavouriteHeart from '@global/FavouriteHeart'
import ListingInspection from './ListingInspection'
import IconBed from '@icons/Bed'
import IconBath from '@icons/Bath'
import IconCar from '@icons/Car'
import Image from '@global/Image'
import * as S from './ListingCard.styled'

const ListingCardAgentOffice = ({ image, title }) => {
  return (
    <S.ListingCardAgentOffice>
      <Image src={image} alt={title} />
      <S.ListingCardAgentOfficeName>{title}</S.ListingCardAgentOfficeName>
    </S.ListingCardAgentOffice>
  )
}

const ListingCard = ({
  dataSource,
  isFeatured,
  hideTags,
  showType,
  quickload,
  results, // In Search Result
  mapView, // In Map
}) => {
  const { setReturnto, token, toggleFavouriteModal } = useContext(UserContext)
  if (!dataSource.id) return null

  const apCookie = getCookie(ACTIVEPIPE_COOKIE)

  // GA: log property view in Google Analytics
  logEvent(
    'Property',
    dataSource.address + ' - ' + dataSource.id,
    'Search Results',
    1
  )

  const { agents, office } = dataSource
  const agent = agents?.[0]

  const handleOpenModalSignup = async (e) => {
    e.preventDefault()

    if (
      !dataSource.offmarket ||
      token ||
      apCookie ||
      dataSource.offmarket_status === 'sold'
    ) {
      return
    }

    const returnName = `Exclusive property in ${dataSource.address}`
    const returnPath = dataSource?.url

    if (returnName && returnPath) {
      await setReturnto({
        name: returnName,
        path: returnPath,
      })
    }
    toggleFavouriteModal()
  }

  return (
    <S.ListingCard
      data-gtm-property-id={dataSource.id}
      data-gtm-office-name={
        (dataSource.office && dataSource.office.title) || 'Barry Plant'
      }
      data-gtm-office-id={dataSource.office && dataSource.office.id}
      data-gtm-created={dataSource.created}
      data-gtm-modified={dataSource.updated}
      data-gtm-agent-id={
        (dataSource.agent_ids &&
          dataSource.agent_ids.length > 0 &&
          dataSource.agent_ids.join()) ||
        ''
      }
      data-gtm-property-type={getPropertyType(dataSource, showType)}
      data-gtm-property-outcome={getOutCome(dataSource, showType)}
      data-gtm-property-price-upper={
        getUpperPrice(dataSource, showType)
          ? getUpperPrice(dataSource, showType)
          : getLowerPrice(dataSource, showType)
      }
      data-gtm-property-price-lower={getLowerPrice(dataSource, showType)}
      data-gtm-property-address={`${dataSource.address}${
        dataSource.address_postcode && ', ' + dataSource.address_postcode
      }`}
      results={results}
      mapView={mapView}
    >
      {(!dataSource.offmarket ||
        (dataSource.offmarket && dataSource.offmarket_status === 'sold') ||
        (token && apCookie)) && (
        <Link
          route={dataSource.url}
          passHref
          prefetch={false}
          target={isExternalURL(dataSource.url) ? '_blank' : '_self'}
        />
      )}

      <span hidden>{(dataSource && dataSource.uniqueID) || ''}</span>
      <S.ListingCardImage>
        <Link
          route={dataSource.url}
          passHref
          prefetch={false}
          target={isExternalURL(dataSource.url) ? '_blank' : '_self'}
        >
          <Image
            quickload={quickload}
            imgMobile={dataSource.image_mobile_url}
            src={dataSource.image_url || defaultImages.mediumNavy}
            fallbackImage={defaultImages.mediumNavy}
            alt={dataSource.address || ''}
          />
        </Link>
        {!dataSource.offmarket && (
          <FavouriteHeart type='listing' id={dataSource.id} />
        )}
        {!hideTags && (
          <ListingTags listing={dataSource} isFeatured={isFeatured} />
        )}

        {dataSource.offmarket &&
          (dataSource.offmarket_status === 'sold' ? (
            <OffMarketOverlay
              listingId={dataSource.id}
              title='SOLD OFF MARKET'
              isShowSignUp={false}
            />
          ) : !token ? (
            <OffMarketOverlay
              token={token}
              listingId={dataSource.id}
              returnName={`Exclusive property in ${dataSource.address}`}
              returnPath={dataSource.url}
              isResults
            />
          ) : null)}
      </S.ListingCardImage>
      <S.ListingCardContent onClick={handleOpenModalSignup}>
        <div>
          <S.ListingCardAddress>
            {dataSource.offmarket && !token && !apCookie ? (
              <span>
                {dataSource.offmarket_status === 'sold' ? (
                  <span>
                    {dataSource.address_display
                      ? dataSource.address_street_display
                      : 'Contact agent for full address'}
                  </span>
                ) : (
                  <span>Unlock to view address</span>
                )}
                <br />
                <span>
                  <strong>{dataSource.address_suburb}</strong>{' '}
                  {dataSource.address_postcode}
                </span>
              </span>
            ) : (
              <Link
                route={dataSource.url}
                passHref
                prefetch={false}
                target={isExternalURL(dataSource.url) ? '_blank' : '_self'}
              >
                <span>
                  <span>
                    {dataSource.address_display
                      ? dataSource.address_street_display
                      : 'Contact agent for full address'}
                  </span>
                  <br />
                  <span>
                    <strong>{dataSource.address_suburb}</strong>{' '}
                    {dataSource.address_postcode}
                  </span>
                </span>
              </Link>
            )}
          </S.ListingCardAddress>
          <S.ListingCardFeatures>
            {dataSource.features?.map((item, index) => (
              <S.ListingCardFeature key={index}>
                <S.ListingCardFeatureLabel>
                  {item.label}
                </S.ListingCardFeatureLabel>
                {item.icon}
              </S.ListingCardFeature>
            ))}
          </S.ListingCardFeatures>
          <S.ListingCardFooter>
            {(!dataSource.offmarket || (dataSource.offmarket && token)) && (
              <S.ListingCardPrice>
                <ListingPrice listing={dataSource} />
              </S.ListingCardPrice>
            )}
            <ListingSaleInfo
              listing={dataSource}
              offmarket={dataSource.offmarket}
              token={token}
              showType={showType}
            />
            {(dataSource.liveAuctionUrl ||
              dataSource.liveAuctionRegisterUrl) && (
              <ListingAuction
                liveAuctionUrl={dataSource.liveAuctionUrl}
                liveAuctionRegisterUrl={dataSource.liveAuctionRegisterUrl}
              />
            )}
            <ListingInspection listing={dataSource} />
          </S.ListingCardFooter>
        </div>
        {results && agents?.length > 0 ? (
          <ListingCardAgentOffice
            title={agent?.title || agent?.agent_details?.title}
            image={
              agent?.profile_image_thumbnail?.url ||
              agent?.agent_details?.profile_image_thumbnail?.url
            }
          />
        ) : office ? (
          <ListingCardAgentOffice
            title={office?.title}
            image={office?.results_image_small}
          />
        ) : null}
      </S.ListingCardContent>
    </S.ListingCard>
  )
}

ListingCard.defaultProps = {
  dataSource: {},
  isNew: false,
  isOpen: false,
}

ListingCard.ApiDataToProps = (data, isListingRow = false) => {
  // Converts API data into the component props
  let dataSource = {}
  dataSource.id = data.id
  dataSource.address_display = data.address_display
  dataSource.address = data.address_street_display
    ? `${data.address_street_display.trim()}`
    : data.address_suburb
  dataSource.priceGuide = data.priceView || data.price || 'Contact Agent'
  dataSource.features = []
  if (data.bedrooms) {
    dataSource.features.push({ icon: <IconBed />, label: data.bedrooms })
  } else if (isListingRow) {
    dataSource.features.push({ icon: <IconBed />, label: 0 })
  }
  if (data.bathrooms) {
    dataSource.features.push({ icon: <IconBath />, label: data.bathrooms })
  } else if (isListingRow) {
    dataSource.features.push({ icon: <IconBath />, label: 0 })
  }
  if (data.total_parking) {
    dataSource.features.push({ icon: <IconCar />, label: data.total_parking })
  } else if (isListingRow) {
    dataSource.features.push({ icon: <IconCar />, label: 0 })
  }

  dataSource.agents = data.agents
  dataSource.image_url =
    data.images && data.images.length && data.images[0].image.image_800_600
  dataSource.image_mobile_url =
    data.images && data.images.length && data.images[0].image.image_320_240

  dataSource.url = data.get_absolute_url
  dataSource.address_postcode = data.address_postcode
  dataSource.auction_date = data.auction_date
  dataSource.status = data.status
  dataSource.soldDetails_price = data.soldDetails_price
  dataSource.soldDetails_price_display = data.soldDetails_price_display
  dataSource.soldDetails_date = data.soldDetails_date
  dataSource.listingType = data.listing_type
  dataSource.salebySetDate = data.salebySetDate
  dataSource.underOffer = data.underOffer
  dataSource.headline = data.headline
  dataSource.address_street_display = data.address_street_display
    ? data.address_street_display.trim()
    : ''
  dataSource.address_postcode = data.address_postcode
  dataSource.address_suburb = data.address_suburb
  dataSource.priceView = data.priceView
  dataSource.price_display = data.price_display
  dataSource.offmarket_status = data.offmarket_status
  dataSource.category = data.category
  dataSource.offmarket =
    data.status === 'offmarket' &&
    data.offmarket_status &&
    (data.offmarket_status === 'current' || data.offmarket_status === 'sold')
      ? true
      : false

  // Turn suburb to title case for offmarket listings
  if (dataSource.offmarket) dataSource.address = titleCase(dataSource.address)

  // handle offmarket listing status
  if (data.status && data.status === 'offmarket' && data.offmarket_status) {
    dataSource.status = data.offmarket_status
  }

  // first inspection
  if (data.inspections && data.inspections.length > 0) {
    dataSource.inspection = data.inspections[0]
    dataSource.inspections = data.inspections
  } else {
    dataSource.inspection = null
  }

  // "New" tag is created < 7 days ago
  const createdDate = new Date(data.created)
  const ONE_WEEK = 60 * 60 * 24 * 7 * 1000 // Milliseconds
  if (new Date() - createdDate < ONE_WEEK) {
    dataSource.new = true
  }

  // listing available date
  if (data.listing_type == 'lease' && data.dateAvailable) {
    dataSource.dateAvailable = availableDate(data.dateAvailable)
  }

  // hrefs
  const hrefs = filterHrefs(data, 'onlineauctions')
  if (hrefs.onlineauctions) dataSource.onlineauctions = hrefs.onlineauctions

  dataSource.liveAuctionUrl = data.live_auction_url
  dataSource.liveAuctionRegisterUrl = data.live_auction_register_url

  // Data attributes
  dataSource.office = data.office
  dataSource.created = data.created
  dataSource.updated = data.updated
  dataSource.agent_ids = data.agent_ids
  dataSource.uniqueID = data.uniqueID

  return { dataSource: dataSource }
}

export default ListingCard
